import { DropdownItemProps } from "semantic-ui-react";
import { GridRowProps } from "@progress/kendo-react-grid";
import { InsuredFile } from "../features/insured/types";
import { PolicyFile } from "../features/policy/types";
import React from "react";
import { TradingCaseFile } from "../features/trading/trading-queue/types";
import config from "../config";
import { getToken } from "./StorageProvider";
import { isDate } from "lodash";
import { ssnRegex } from "./Constants";

export const kendoDateFormat = "{0: MM/dd/yyyy}";
export const kendoDateTimeFormat = "{0: MM/dd/yyyy, h:mm a}";
export const kendoCurrencyFormat = "{0:c2}";
export const defaultSiteTitle = `ITM TwentyFirst Servicing`;
export const emdash = `—`;

/**
 * Returns an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} dateFields - An array of field names to be parsed as Date objects
 */
const shortISODateRegex = /^\d{4}-\d{2}-\d{2}$/;
export const parseDates = <T>(data: T[], fields: (keyof T)[], behavior?: "TRIM Z"): T[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = mutableRow[field] as any;
            if (value) {
                let preparedValue = value;
                if (behavior === "TRIM Z" && value.endsWith("Z")) {
                    preparedValue = value.slice(0, -1);
                } else if (shortISODateRegex.test(value)) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    mutableRow[field] = fromIsoDateString(value as string) as any;
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutableRow[field] = new Date(preparedValue) as any;
            }
        });
        return mutableRow;
    });

    return parsed as T[];
};

export function fromIsoDateString(date: string): Date {
    const [year, month, day] = date.split("-");
    return new Date(+year, +month - 1, +day);
}

export function toIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

export const getLocalFormDate = (date?: Date) => {
    if (date) {
        return toIsoDateString(date);
    }
    return "";
};

export function displayDate(date?: Date | string | undefined | null) {
    if (isDate(date)) {
        return date.toLocaleDateString();
    } else if (typeof date === `string`) {
        return `INVALID DATE`;
    }
    return null;
}

export function displayISODateForForm(date?: Date | string | undefined | null) {
    if (isDate(date)) {
        return toIsoDateString(date);
    } else if (typeof date === `string`) {
        return date;
    }
    return null;
}

export function getISODateTimeStringLocal(date: Date): string {
    const offsetMs = date.getTimezoneOffset() * 60 * 1000;
    const msLocal = date.getTime() - offsetMs;
    const dateLocal = new Date(msLocal);
    const iso = dateLocal.toISOString();
    const isoLocal = iso.slice(0, -1);
    return isoLocal;
}

export const getFirstOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    return new Date(refDate.getFullYear(), refDate.getMonth(), 1);
};

export const addMonthsToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth() + n, date.getDate());
};

export const addDaysToDate = (date: Date, n: number): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + n);
};

export const getLastOfMonth = (date?: Date): Date => {
    const refDate = date ?? new Date();
    const firstOfNextMonth = addMonthsToDate(getFirstOfMonth(refDate), 1);
    return addDaysToDate(firstOfNextMonth, -1);
};

export const formatCurrency = (value: number, decimals = 2): string => {
    return (
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        })
            .format(value)
            //Erase decimals with only trailing 0s
            .replace(/\.[0]+$/, "")
    );
};

export const getIsoDate = (date: string | null): string => {
    if (!date) {
        return "";
    }
    return date.split("T")[0];
};

export const getUpdatedValues = <T extends Record<string, unknown>>(original: T, modified: T): Partial<T> => {
    const updates: Record<string, unknown> = {};
    Object.entries(modified).forEach(([property, value]) => {
        if ((original as Record<string, unknown>)[property] !== value) {
            updates[property] = value;
        }
    });
    return updates as Partial<T>;
};

export const notImplemented = () => {
    throw new Error(`Not implemented!`);
};

export const displayOptionsTextFromValue = (value: string | number | null, options: DropdownItemProps[] | undefined) => {
    if (!value || !options || !options.length) return;
    return (options.filter((u) => u.value === value)[0] || {}).text;
};

export const getPolicyStandingStatusColor = (policyStandingStatus: string | null | undefined) => {
    switch (policyStandingStatus) {
        case "Servicing":
        case "Good":
            return "green";
        case "Matured":
        case "Sold":
        case "Grace":
            return "yellow";
        case "Lapsed":
            return "red";
        default:
            return undefined;
    }
};

export const getCooperationColor = (value: number | null | undefined) => {
    switch (value) {
        case 1:
            return "yellow";
        case 2:
            return "orange";
        case 3:
            return "red";
        default:
            return undefined;
    }
};

export const getCooperationTextColor = (value: number | null | undefined) => {
    switch (value) {
        case 3:
            return "";
        default:
            return "text-black";
    }
};

export const getBulkDownload = async (
    data: {
        FileName: string;
        FileKey: string;
        FolderPath: string;
        Bucket?: string;
    }[]
): Promise<ArrayBuffer> => {
    const token = getToken();
    const response = await fetch(`${config.fileApiUrl}/bulk`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(data)
    });
    const responseData = await response.arrayBuffer();
    if (!response.ok) {
        const error = new TextDecoder().decode(responseData);
        throw new Error(error);
    }
    return responseData;
};

export const setHighlightedFileRow = (files: (InsuredFile | PolicyFile | TradingCaseFile)[], fileID?: number) => {
    const tempFiles = files.map((item) => ({ ...item, highlighted: false, selected: false }));
    if (fileID) {
        const idx = tempFiles.findIndex((item) => item.FileID === fileID);
        tempFiles[idx] = { ...files[idx], highlighted: true, selected: false };
    }
    return tempFiles;
};

export const fileRowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const { highlighted } = props.dataItem as InsuredFile | PolicyFile;
    const bgColor = { backgroundColor: "#0d6efd40" }; // standard kendo grid selected row colour
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trProps: any = { style: highlighted ? bgColor : undefined };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setHighlightedGridRow = (array: any[], primaryKey: string, id?: number) => {
    const temp = array.map((item) => ({ ...item, highlighted: false }));
    if (id) {
        const idx = temp.findIndex((item) => item[primaryKey] === id);
        temp[idx] = { ...array[idx], highlighted: true };
    }
    return temp;
};

export const highlightRowRender = (trElement: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => {
    const { highlighted } = props.dataItem;
    const bgColor = { backgroundColor: "#0d6efd40" }; // standard kendo grid selected row colour
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trProps: any = { style: highlighted ? bgColor : undefined };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
};

export const formatSSN = (ssn: string): string => {
    if (!ssn || ssn.length !== 9) {
        return ssn;
    }
    return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5, 9)}`;
};

export const isValidSSN = (ssn: string): boolean => {
    return ssnRegex.test(ssn);
};

// converts OLE dates to standard dates
export const processProjectionDate = (val: string): string => {
    const regex = /[-/]/;
    const stdDate = regex.test(val);
    let date: Date;
    let dateString: string;

    if (stdDate) {
        const tempStdDate = new Date(val);
        date = applyTimezone(tempStdDate);
    } else {
        const oleDate = parseFloat(val);
        const intDate = oleDate * 24 * 60 * 60 * 1000;
        const ole = new Date(Date.UTC(1899, 11, 30) + intDate);
        date = applyTimezone(ole);
    }

    if (isNaN(date.getTime())) {
        dateString = val;
    } else {
        dateString = toIsoDateString(date);
    }

    return dateString;
};

// adjust date to central time
export const applyTimezone = (date: Date): Date => {
    const centralTimeOffset = 6;
    const adjustedDate = new Date(date.getTime() + centralTimeOffset * 60 * 60 * 1000);
    return adjustedDate;
};

// shared function to get tracking history notes
export const getTrackingHistoryNotes = (TrackingType: string, Notes: string | null) => {
    switch (TrackingType) {
        case "LE Order":
            return "This track was created by a completed LE with a Medical Records end date.";
        case "Medical Record":
            return "This track was created by a completed MedRecs order.";
        default:
            return Notes || "";
    }
};

const dateFormatOptions: Intl.DateTimeFormatOptions = {
    // MM/DD/YYYY
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
};

export const getFormattedDate = (date?: Date) => {
    const formatted = (date || new Date()).toLocaleDateString("en-US", dateFormatOptions);
    return formatted;
};

// accepts various date strings (OLE, YYYY-MM-DD, MM/DD/YYYY) and returns a formatted date string
export const processDate = (val: string): string => {
    const regex = /[-/]/;
    const stdDate = regex.test(val);
    let date: Date;
    let dateString: string;

    if (stdDate) {
        const tempStdDate = new Date(val);
        date = applyTimezone(tempStdDate);
    } else {
        const oleDate = parseFloat(val);
        const intDate = oleDate * 24 * 60 * 60 * 1000;
        const ole = new Date(Date.UTC(1899, 11, 30) + intDate);
        date = applyTimezone(ole);
    }

    if (isNaN(date.getTime())) {
        dateString = val;
    } else {
        dateString = getFormattedDate(date);
    }

    return dateString;
};
